import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Banner, Box, Button, LoadingBox, Popup, Text } from '@looxr/components';
import { LEAK_PORTAL_URL, PRESSURE_PORTAL_URL } from '@looxr/constants';
import { useLanguage, useTranslation } from '@looxr/utils';
import { BOX_TYPES } from '../constants';
import useLoadLeakSummary from '../hooks/useLoadLeakSummary';
import useLoadPressureSummary from '../hooks/useLoadPressureSummary';
import { AppStateService, FirebaseService } from '../services';
import SummaryButtons from './SummaryButtons';
import SummaryContentBlock from './SummaryContentBlock';
import SummaryEditModal from './SummaryEditModal';

const StyledSummaryBox = styled(Box)`
  margin-bottom: 1.875rem;

  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    margin: 1rem 1.875rem;
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    width: 41%;
    max-width: 500px;
  }
`;

function SummaryBox({ boxConfig }) {
  const language = useLanguage();
  const tn = useTranslation();
  const { getLeakLocationSummaryQuery, mapSummaryWithConfig } = useLoadLeakSummary();
  const { getPressureSignalValues } = useLoadPressureSummary();

  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const [showDeletePopup, setDeleteShowPopup] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [boxValues, setBoxValues] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    setLoading(true);

    const updateLeakValues = (snapshot) => {
      setLoading(true);
      setHasError(false);

      if (snapshot.docs.length > 0) {
        const summaryDoc = snapshot.docs[0];
        const data = summaryDoc.data();
        const mapped = mapSummaryWithConfig(boxConfig, data);

        console.log('mapped', mapped);

        if (mapped !== false && mapped.length > 0) {
          setBoxValues(mapped);
        } else {
          setHasError(true);
        }
      } else {
        setHasError(true);
      }
      setLoading(false);
    };

    const updatePressureValues = async () => {
      setLoading(true);

      const mapped = await getPressureSignalValues(boxConfig);

      if (mapped !== false && mapped.length > 0) {
        setBoxValues(mapped);
      } else {
        setHasError(true);
      }

      setLoading(false);
    };

    let pressureInterval = null;
    let leakUnsub = null;

    if (boxConfig) {
      let hasAccessForBox = false;

      if (boxConfig.type === BOX_TYPES.leak && AppStateService.user.logins.leak) {
        hasAccessForBox = true;
      }

      if (boxConfig.type === BOX_TYPES.pressure && AppStateService.user.logins.pressure) {
        hasAccessForBox = true;
      }

      if (hasAccessForBox) {
        setHasAccess(true);
        if (boxConfig.type === BOX_TYPES.leak) {
          leakUnsub = getLeakLocationSummaryQuery(boxConfig.customer).onSnapshot(updateLeakValues);
        } else {
          pressureInterval = setInterval(updatePressureValues, 150000);
          updatePressureValues();
        }
      } else {
        setLoading(false);
        setHasError(true);
      }
    }

    return () => {
      if (pressureInterval) {
        clearInterval(pressureInterval);
      }

      if (leakUnsub) {
        leakUnsub();
      }
    };
  }, [boxConfig, language]);

  const deleteSummaryBox = () => {
    FirebaseService.deleteSummaryBox(boxConfig.id);
  };

  const optOut = (boxConfig) => {
    if (boxConfig.type === BOX_TYPES.leak) {
      window.location.href = `${LEAK_PORTAL_URL}/optin/${boxConfig.customer.id}`;
    }

    if (boxConfig.type === BOX_TYPES.pressure) {
      window.location.href = PRESSURE_PORTAL_URL;
    }
  };

  if (!boxConfig) {
    return null;
  }

  return (
    <>
      <StyledSummaryBox
        id={boxConfig.id}
        background="white"
        paddingBottom={8}
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
      >
        <Box position="relative">
          <Banner subtext={boxConfig.name} inBox type={boxConfig.type} />
          <SummaryButtons
            visible={showButtons}
            hasAccess={hasAccess}
            onEditClick={() => setShowEdit(true)}
            onDeleteClick={() => setDeleteShowPopup(true)}
          />
        </Box>
        <LoadingBox
          minHeight={180}
          height="auto"
          loading={loading}
          renderChildren={!loading}
          display="flex"
          wrap="wrap"
          paddingX={2}
          paddingY={6}
        >
          {!hasError &&
            boxValues.map((value) => (
              <SummaryContentBlock
                key={value.name}
                type={boxConfig.type}
                label={value.label}
                value={value.value}
                unit={value.unit}
                digits={value.digits}
              />
            ))}

          {hasError && (
            <Box
              justify="center"
              display="flex"
              width="100%"
              alignItems="center"
              alignContent="center"
            >
              <Text size="sm">
                {tn('landing.summarybox.errorNoDataText')}
                {/* Es konnten keine Daten geladen werden */}
              </Text>
            </Box>
          )}
        </LoadingBox>
        <Box display="flex" justify="center" alignItems="center">
          <Box>
            <Button
              background={boxConfig.type === BOX_TYPES.leak ? 'purple' : 'blue'}
              text={
                boxConfig.type === BOX_TYPES.leak
                  ? tn('landing.summarybox.buttons.leak')
                  : tn('landing.summarybox.buttons.pressure')
              }
              minWidth={200}
              onClick={() => optOut(boxConfig)}
            />
          </Box>
        </Box>
      </StyledSummaryBox>

      <SummaryEditModal
        boxConfig={boxConfig}
        isVisible={showEdit}
        onClose={() => setShowEdit(false)}
      />

      <Popup
        show={showDeletePopup}
        title={tn('landing.popup.deleteSummary.title')} // "Konfiguration löschen"
        message={tn('landing.popup.deleteSummary.text')} // "Möchten Sie diese Konfiguration wirklich löschen?"
        confirmText={tn('landing.popup.deleteSummary.buttonYes')} // "Jetzt löschen"
        abortText={tn('landing.popup.deleteSummary.buttonNo')} // "Abbrechen"
        confirmColor="green"
        abortColor="red"
        confirmAction={() => deleteSummaryBox()}
        abortAction={() => setDeleteShowPopup(false)}
        blockScroll={false}
      />
    </>
  );
}

SummaryBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  boxConfig: PropTypes.any
};

SummaryBox.defaultProps = {
  id: null,
  boxConfig: null
};

export default SummaryBox;
